import $ from 'jquery';

$("body").on('click', '[href*="#"]', function(e){
    var fixed_offset = 5;
    $('html,body').stop().animate({ scrollTop: $(this.hash).offset().top - fixed_offset }, 1000);
    e.preventDefault();
});



$(document).ready(function() {
	$('body').mousemove(function(e) {
		parallax(e);
	});
});

function parallax(e) {
    const coordsxleft = e.pageX/25 + 'px';
    const coordsyleft = e.pageY/25 + 'px';
    const coordsxleft_2 = e.pageX/35 + 'px';
    const coordsyleft_2 = e.pageY/35 + 'px';
    const coordsxleft_3 = e.pageX/45 + 'px';
    const coordsyleft_3 = e.pageY/45 + 'px'
    const coordsxright = e.pageX/10 + 'px';
    const coordsyright = e.pageY/10 + 'px';
    const coordsxright_2 = e.pageX/12.5 + 'px';
    const coordsyright_2 = e.pageY/12.5 + 'px';
    const coordsxright_3 = e.pageX/15 + 'px';
    const coordsyright_3 = e.pageY/15 + 'px';
    const coordsxplane = -e.pageX/15 + 'px';
    const coordsyplane = -e.pageY/15 + 'px';
    $('#parallaxCloudLeft').css({"transform": "translate(" + coordsxleft + "," + coordsyleft + ")"});
    $('#parallaxCloudLeft2').css({"transform": "translate(" + coordsxleft_2 + "," + coordsyleft_2 + ")"});
    $('#parallaxCloudLeft3').css({"transform": "translate(" + coordsxleft_3 + "," + coordsyleft_3 + ")"});
    $('#parallaxCloudRight').css({"transform": "translate(" + coordsxright + "," + coordsyright + ")"});
    $('#parallaxCloudRight2').css({"transform": "translate(" + coordsxright_2 + "," + coordsyright_2 + ")"});
    $('#parallaxCloudRight3').css({"transform": "translate(" + coordsxright_3 + "," + coordsyright_3 + ")"});
    $('#parallaxPlane').css({"transform": "translate(" + coordsxplane + "," + coordsyplane + ")"});
};