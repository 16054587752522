import $ from 'jquery';


$( document ).ready(function(){
    $('.tours__slider').slick({
        arrows: true,
        centerMode: true,
        centerPadding: '20%',
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                centerPadding: '10%'
                }
            },
            {
                breakpoint: 992,
                settings: {
                arrows: false,
                centerPadding: false,
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
      });
})