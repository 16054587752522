import $ from 'jquery';
import IMask from 'imask';

$(document).ready(function () {
    $('#form').on('submit', function () {
        $.ajax({
            type: 'POST'
            , url: './../../../send.php'
            , data: $(this).serialize()
            , success: function (data) {
                if (data) {
                  alert('Спасибо, ваша заявка отправлена!')
                }
            }
            , error: function (xhr, str) {
                alert('Возникла ошибка: ' + xhr.responseCode);
                location.reload();
            }
        });
    });
  });

var items = document.querySelectorAll('.form__input_phone');
Array.prototype.forEach.call(items, function(element) {
    console.log(element);
    new IMask(element, {
        mask: '+{7} (000) 000-00-00',
        placeholder: {
            show: 'always'
        }
        
    });
});