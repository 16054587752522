const servicesItem = document.querySelectorAll('.services__item');

function servicesItemHeight() {
    servicesItem.forEach((element, index) => {
        element.style.height = getComputedStyle(element).width;
    })
}

servicesItemHeight();
window.onresize = function(event) {
    servicesItemHeight();
};